@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
    font-family: 'Noto Sans', sans-serif;
}

.cssLoginContainer {
    background-color: #ffffff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
}

.cssLoginIzq {
    background: transparent url(../img/bg-login-2.jpg) no-repeat center /cover;
    position: fixed;
    width: 50%;
    height: 100vh;
    overflow: hidden;
    left: 0;
    top: 0;
}

.cssLoginDer {
    background: #ffffff no-repeat center /cover;
    position: fixed;
    width: 50%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    right: 0;
}

.cssLogoSmartEd {
    background: transparent url(../img/LogoAlumdata.svg) no-repeat center /contain; 
    margin: auto;
    display: flex;
    width: 66%;
    flex-direction: column;
    height: 100px;
    position: relative;
}

.cssLogoDgmx {
    background: transparent url(../img/IsotipoAlumdata.svg) no-repeat center /contain; 
    margin: auto;
    display: flex;
    width: 25%;
    flex-direction: row;
    height: 100px;
    position: relative;
    float: right;
}

.form {
    background-color: #ffffff;
    box-shadow: 2px 2px 10px #333;
    width: 33%;
    height: 300px; 
    display: flex;
    border-radius: 10px;
    margin: auto;
    margin-top: 2rem;
}

.iconInputs {
    width: 25px;
    position: absolute;
    padding: 0.25rem 1rem;
    margin-top: 10px;
}

@media screen and (max-width:1000px) {
    .cssLoginIzq {
        width: 100%;
        top: 0; 
        left: 0;
        height: 35vh;
    }

    .cssLoginDer {
        width: 100%;
        background-color: transparent;
        font-size: 2vh;
    }

    .cssLoginDer h1 {
        font-size: 1.1rem;
        margin-bottom: 1rem;
    }

    .cssLoginDer h2 {
        font-size: 1.1rem;
        margin-bottom: 2rem;
    }

    .cssLogoSmartEd {
        width: 100%;
        height: 10vh;
        background-color: rgba(255,255,255,0.7);
        margin-top: -8vh;
    }

    .cssLoginText {
        position: relative;
        top: 24vh;
    }

    .cssLoginDer .mt-14 {
        margin-top: 15vh;
    }

    .cssLoginDer .p-3 {
        padding: 0px;
    }

    .cssLoginDer form {
        width: 100%;
        margin: auto;
        margin-top: 20vh !important;
    }
    
    .cssLoginDer form input {
        border-radius: 2rem;
        font-size: 1rem;
        background-color: white;
    }

    .cssLoginDer form .h-16 {
        height: 7vh;
    }

    .cssLoginDer br {
        display: none;
    }

    .cssLoginDer button {
        font-size: 1rem;
    }

    .cssLoginDer a {
        font-size: 1rem;
    }

    .cssLoginDer .iconInputs {
        margin-top: 12px;
    }

    .cssLoginDer a svg {
        width: 1rem;
        height: 1rem;
    }

    .cssLoginDer a span {
        margin-top: 0.25rem;
    }
}

@media screen and (max-height:560px) {
    .cssLoginDer {
        overflow-y: auto;
    }
}