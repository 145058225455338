@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@font-face {
  font-family: 'Maax-Medium';
  src: local('Maax Medium'), local('Maax-Medium');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maax-Italic';
  src: local('Maax Italic'), local('Maax-Italic');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Maax-BoldItalic';
  src: local('Maax BoldItalic'), local('Maax-BoldItalic');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Maax-Black';
  src: local('Maax Black'), local('Maax-Black');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Maax';
  src: local('Maax');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maax-Bold';
  src: local('Maax Bold'), local('Maax-Bold');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Maax-Mediumitalic';
  src: local('Maax MediumItalic'), local('Maax-Mediumitalic');
  font-weight: 500;
  font-style: italic;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

.headerSetup h3 {
    font-family: 'Open Sans', sans-serif;
}

.backgroundSteps {
    position: fixed; 
    left: 0; 
    bottom: 0;
    z-index: 0;
}

.rocketImg {
    position: fixed;
    z-index: 10;
    bottom: 1vh;
    left: -3vh;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));
}

.logoHeaderAutosetup {
    position: absolute;
    right: 0vw;
    top: 2vh;
}

.footerSetup {
    width: 100%;
    bottom: 0;
    height: 10vh;
    position: absolute;
}

.logoFooterAutosetup {
    position: fixed;
    right: 0vw;
    bottom: 2vh;
}

.helpAutosetup {
    font-family: 'Maax-Bold', sans-serif;
    background-color: #40769A;
    position: fixed;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    left: 2rem;
    bottom: 1.5rem;
    border-radius: 50px;
    z-index: 20;
    color: #ffffff;
    margin: auto;
    padding: 10px;
    width: 50px;
    height: 50px;
}

.helpAutosetup:hover {
    cursor: pointer; 
    background-color: #378ac2;
}

.AutosetupStep01 {
    position: absolute; 
    bottom: 8vh; 
    right: 9vw;
}