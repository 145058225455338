@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@font-face {
  font-family: 'Maax-Bold';
  src: url('../fonts/Maax-Bold.eot');
  src: url('../fonts/Maax-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Maax-Bold.woff') format('woff'),
    url('../fonts/Maax-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

.containModal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.containModalRecover {
  width: 50%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.5);
}

.modalSeguimiento {
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.modalContent {
  height: auto;
}

.cerrarModal {
  top: 0;
}

.tituloModal {
  font-family: 'Maax-Bold', sans-serif;
}
