@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@font-face {
  font-family: 'Maax-Medium';
  src: local('Maax Medium'), local('Maax-Medium');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Maax-Italic';
  src: local('Maax Italic'), local('Maax-Italic');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Maax-BoldItalic';
  src: local('Maax BoldItalic'), local('Maax-BoldItalic');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Maax-Black';
  src: local('Maax Black'), local('Maax-Black');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Maax';
  src: local('Maax');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Maax-Bold';
  src: local('Maax Bold'), local('Maax-Bold');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Maax-Mediumitalic';
  src: local('Maax MediumItalic'), local('Maax-Mediumitalic');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Maax';
  src: url('css/fonts/Maax.eot');
  src: url('css/fonts/Maax.eot?#iefix') format('embedded-opentype'),
    url('css/fonts/Maax.woff') format('woff'),
    url('css/fonts/Maax.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Noto Sans', sans-serif;
}

.cssHeader {
  background-color: #40769a;
  height: 60px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.sideBarMenu {
  border-right: solid 1px rgba(170, 170, 170, 0.7);
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 33;
}

.opcionMenu {
  cursor: pointer;
}

.opcionCloseMenu {
  position: absolute;
  bottom: 1.5rem;
  margin: 0.75rem;
  padding: 0.5rem;
  cursor: pointer;
}

.footerLogo {
  position: fixed;
  background-color: #ffffff;
  height: 55px;
  width: 100%;
  bottom: 0;
  left: 0;
}

.cssLogoSmartSm {
  background: transparent url(css/img/LogoAlumdata.svg) no-repeat center /
    contain;
  margin: auto;
  width: 20%;
  height: 40px;
  position: fixed;
  float: right;
  bottom: 10px;
  right: 20px;
  padding: 0.5rem;
}

.loadButton {
  cursor: not-allowed !important;
}

.loadButton [role="button"] {
  cursor: not-allowed !important;
}

.cardsContainer {
  position: relative;
  top: 3vh;
  width: 88%;
  left: 2rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 5%;
}

.cardsContainerProspects {
  position: relative;
  top: 3vh;
  width: 88%;
  left: 2rem;
  max-height: 80vh;
  overflow-y: auto;
  padding-bottom: 5%;
}

.flipCard {
  background-color: transparent;
  width: 22%;
  height: 430px;
  margin: 1rem 1rem;
  cursor: pointer;
  padding: 0;
}

.flip-card-inner {
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}

.flipCard:hover .flip-card-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.flipCardUser {
  background-color: transparent;
  width: 22%;
  height: 430px;
  margin: 1rem 1rem;
  cursor: pointer;
  padding: 0;
}

.flip-card-user-inner {
  transform-style: preserve-3d;
  transition: all 0.5s ease;
}

.flipCardUser:hover .flip-card-user-inner {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

/*.cardFront {
  transition: all 0.8s ease;
  display: block;
}

.cardBack {
  transition: all 0.8s ease;
  display: none;
}*/

.cardHome {
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #d2d2d2;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
  height:430px;
  position: absolute;
  backface-visibility: hidden;
}

.cardUserHome {
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #d2d2d2;
  border-radius: 20px;
  cursor: pointer;
  height: 430px;
  width: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.cardHomeBack {
  /*background-image: linear-gradient(
    to bottom,
    #72d9ef,
    #84d7ff,
    #a0d2ff,
    #c1cdff,
    #dfc7ff
  );*/
  background: linear-gradient(
    180deg,
    rgba(148, 201, 255, 1) 0%,
    rgba(145, 218, 245, 1) 50%,
    rgba(202, 203, 255, 1) 100%
  );
  box-shadow: 2px 2px 5px #d2d2d2;
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
  height: 430px;
  position: absolute;
  top: 0;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  left: -2rem;
}

.cardUser {
  background-color: #ffffff;
  box-shadow: 2px 2px 5px #d2d2d2;
  border-radius: 20px;
  width: 22%;
  height: 300px;
}

.cssMenuExpandido {
  position: absolute;
  left: 4rem;
}

.logoSmartMenu {
  background: transparent url(css/img/LogoAlumdata.svg) no-repeat center /
    contain;
  margin: auto;
  width: 80%;
  height: 70px;
  padding: 0.5rem;
}

.menuExpand {
  width: 90%;
  text-align: left;
}

.elementoMenuExpandido {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 35px;
}

.imgDetalle .avatarDetalle img {
  width: 55px !important;
}

.cssLogoDgmxMenu {
  background: transparent url(css/img/IsotipoAlumdata.svg) no-repeat center /
    contain;
  margin: auto;
  display: flex;
  width: 80%;
  flex-direction: row;
  height: 40px;
  position: relative;
  float: right;
  top: 2rem;
}

.borderGradoInteres {
  border-radius: 70px;
  border-width: 5px;
}

.gradoAspiranteInd {
  position: relative;
  float: left;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  margin: 0.25rem;
  margin-right: 0.375rem;
}

.iconNombreAspirante {
  cursor: pointer;
}

.analyticContainer {
  background-color: #eeeeee;
  min-width: 90%;
  min-height: 300px;
}

.analyticGeneralContainer {
  min-height: 44vh;
  max-height: 65vh;
  overflow-y: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'a b c '
    'd e';
}

.notificationsContainer {
  background-color: #f9f9f9;
  position: fixed;
  top: 60px;
  right: 0;
  width: 22vw;
  height: 100vh;
  z-index: 30;
}

.containerIndividualNotification {
  position: relative;
  width: 100%;
  min-height: 50px;
  border-bottom: solid 1px #aaaaaa;
  left: 0;
  cursor: pointer;
}

.notificationTop {
  padding-bottom: 0.25rem;
}

.notificationBottom {
  float: right;
  position: relative;
}

.tabsAnaliticos .PrivateTabIndicator-colorSecondary-3 {
  background-color: #033f67 !important;
}

.tabsAnaliticos .css-cjctlb-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #033f67 !important;
}

.MuiTabs-indicator {
  background-color: #033f67 !important;
}

.numProspectos {
  font-family: 'Maax-bold', sans-serif;
}

.detalleLicencia {
  overflow-y: auto;
  height: 70vh;
  padding-bottom: 10vh;
}

.spanNameHeader {
  border-bottom: solid 1px white;
}

.goBackButton {
  width: 35px;
  height: 35px;
}

.tooltipProgress {
  background-color: rgba(255,255,255,0.8);
  white-space: nowrap;
  position: absolute;
  top: -1.25rem;
  left: 2.5rem;
  padding: 0.1rem 0.5rem;
  border-radius: 5px;
}

.cssAccountContainer {
  overflow-y: auto;
}

@media screen and (min-height: 900px) {
  .cardsContainer {
    max-height: 85vh;
  }

  .footerLogo {
    height: 80px;
  }

  .cssLogoSmartSm {
    bottom: 20px;
    height: 45px;
  }
}

.thermometer {
  height: 400px;
  transform: rotate(90deg);
  top: -180px;
  /*width: auto;*/
  left: 42%;
}

.thermometer__draw-b::after {
  background-color: #2196f3 !important;
}
.thermometer__draw-b::before {
  background-color: #2196f3 !important;
}
.thermometer .thermometer__bg-color {
  background: linear-gradient(#f44336, #8bc34a, #2196f3) !important;
}

.reportGeneralContainer {
  width: 96%;
  margin-top: 7vh !important;
  overflow: auto;
  padding: 0 0 0 2rem;
}

.analyticsOutsideContainer {
  width: 85%;
  margin-left: auto;
}

@media screen and (max-width: 650px) {
  .cardsContainer {
    left: 1rem;
    width: 75%;
  }

  .flipCardUser {
    width: 90%;
  }

  .cardsContainerProspects {
    left: 1rem;
    width: 75%;
  }

  .flipCard {
    width: 90%;
  }

  .cssMenuExpandido {
    width: 70vw;
    position: fixed;
    z-index: 30;
  }

  .notificationsContainer {
    width: 50vw;
  }

  .cssMyAccount {
    grid-template-columns: unset;
  }

  .cssAccountContainer {
    width: 80%;
    left: 1.5rem;
  }

  .cssMyAccount input {
    font-size: 0.9rem;
  }

  .cssAccountContainerGral .buttonForm {
    position: relative;
    left: 30vw;
    float: left;
  }

  .cssAccountContainerGral .buttonForm button {
    font-size: 1em !important;
    width: 50vw;
  }

  .cssAccountContainer .titleProfile {
    margin-top: 5vh;
  }

  .containerTopActions {
    right: 0;
    width: 105%;
  }

  .containerTopActions .spanNameHeader {
    display: none;
  }

  .containerTopActions .spanNameUser {
    display: none;
  }

  .containerTopActions .userImgMini {
    margin-right: 2rem;
  }

  .cssTitlePage {
    margin-left: 3vw;
    white-space: nowrap;
  }

  .detalleImagenLicencia {
    width: 50%;
  }

  .contenidoLicencia {
    width: 90%;
    margin-left: 13vw;
  }

  .contenidoLicencia form input {
    font-size: 0.9rem;
  }

  .tabsAnaliticos {
    width: 82% !important;
    margin-left: 17vw;
  }

  .analyticsFilters {
    display: block !important;
    width: 100%;
  }

  .analyticsFilters select {
    font-size: 0.9rem !important;
    padding-left: 1.5rem;
  }

  .analyticChartResp {
    display: block !important;
    width: 100% !important;
    margin-top: -2rem;
  }

  .analyticChartResp .highcharts-container {
    margin-top: 2rem;
  }

  .analyticGeneralContainer {
    grid-template-columns: inherit !important;
    grid-template-rows: inherit !important;
  }

  .analyticGeneralContainer .highcharts-container {
    width: 100% !important;
  }

  .MuiButtonBase-root {
    width: 30%;
    font-size: 0.7rem !important;
    left: 15px !important;
  }

  .css-bq23jk {
    width: 90% !important;
  }

  .MuiTabPanel-root {
    padding: 15px !important;
    margin-left: 15px;
  }

  .tabsReportes {
    width: 85% !important;
    margin-left: 15vw;
    margin-top: 8vh;
  }

  .reportsFilters {
    display: block !important;
    width: 95%;
    height: 30vh;
    overflow: auto;
  }

  .reportsFilters select {
    font-size: 0.9rem !important;
    padding-left: 1.5rem;
  }

  .smartDatatable th {
    min-width: 100px !important;
  }

  .rdt_TableCell {
    min-width: 100px !important;
  }

  .dataTableContainer {
    width: 70vw;
    height: 60vh;
    overflow: auto;
    margin-top: 5vh;
  }

  .fSQziN {
    overflow-y: initial !important;
    overflow-x: initial !important;
  }

  .containModal .modalContent {
    width: 75% !important;
    margin-left: 5rem;
  }

  .containModal .modalContent button {
    padding-left: 2.25rem;
  }

  .containModal select {
    font-size: 0.9rem;
  }

  .containModal input {
    font-size: 0.9rem;
  }

  .containModal label {
    font-size: 0.9rem;
  }

  .detalleAspiranteResp {
    display: block !important;
    width: 80%;
    grid-template-columns: inherit;
    margin-left: 17vw;
  }

  .titleDetalleAspResp {
    width: 90%;
    margin-left: 10vw;
  }

  .fSQziN {
    overflow-y: initial !important;
    overflow-x: initial !important;
  }

  .csxlVi {
    overflow: visible !important;
  }
}
